module.exports = function searchForm() {

    /************* Expandable search form *****************/
    var submitIcon = $('.icon-search'),
        inputBox = $('.search-input'),
        searchBox = $('.searchbox'),
        inputSubmit = $('.search-submit'),
        isOpen = false;

    submitIcon.click(function () {

        if (!isOpen) {
            searchBox.addClass('searchbox-open');
            //inputSubmit.addClass('z-index-1');
            inputBox.focus();
            isOpen = true;
        } else {
            searchBox.removeClass('searchbox-open');
            //inputSubmit.removeClass('z-index-1');
            // inputBox.focusout();
            isOpen = false;
        }
    });

    // check if there are values in search input text
    // submit if true
    function buttonUp() {
        var inputVal = $('.search-input').val();
        inputVal = $.trim(inputVal).length;

        if (inputVal !== 0) {
            $('.icon-search').css('display', 'none');
        } else {
            // $('.search-input').val('');
            $('.icon-search').css('display', 'block');
        }
    }

    $('.search-input').on('keypress', function () {
        buttonUp();
    });
}