module.exports = function owlCarousel() {

    var $home = $('body').hasClass('home');

    if ($home) {
        // console.log('Home');
        import('owl.carousel2').then((owlCarousel) => {


            $('.owl-carousel').owlCarousel({
                loop: true,
                autoplay: false,
                nav: true,
                dots: false,
                margin: 5,
                responsive: {
                    0: {
                        items: 1,
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 3
                    },
                    1200: {
                        items: 4
                    },
                    1900: {
                        items: 5
                    }
                }
            })

        })

    }
}