module.exports = function headerScroll() {

    $(document).on('scroll', function () {
        var $scroll = $(this).scrollTop(),
            $headerTop = $('.header-top');

        if ($scroll > 15) {
            $headerTop.addClass('fixed');
        } else {
            $headerTop.removeClass('fixed');
        };
    });
}