import 'bootstrap'
const mobileNav = require('./assets/mobileNav')
const searchForm = require('./assets/searchform')
const owlCarousel = require('./assets/owl.carousel')
const css = require('./scss/style.scss')
const header = require('./assets/header')


// mobileNav for responsive navigation
mobileNav()

// Expanding searchFrom on click
searchForm()

// Agenda Homepage carousel
owlCarousel()

// header behaviour on scroll
header();

// Add a title to main logo
$('.custom-logo-link').attr('title', 'retour à l\'accueil du Blog CAUE 34');

// Open all PDFs in a new window
$(function() {
    $('a[href$=".pdf"]').prop('target', '_blank');
});
